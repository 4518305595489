const actionTypes = {
  // fetching
  START_FETCHING: 'fetching/START_FETCHING',
  STOP_FETCHING: 'fetching/STOP_FETCHING',
  
  START_FETCHING_HOLD: 'fetching/START_FETCHING_HOLD',
  STOP_FETCHING_HOLD: 'fetching/STOP_FETCHING_HOLD',

  START_FETCHING_FLIGHT: 'fetching/START_FETCHING_FLIGHT',
  STOP_FETCHING_FLIGHT: 'fetching/STOP_FETCHING_FLIGHT',

  START_FETCHING_HOTEL: 'fetching/START_FETCHING_HOTEL',
  STOP_FETCHING_HOTEL: 'fetching/STOP_FETCHING_HOTEL',
  START_FETCHING_HOTEL_ROOM: 'fetching/START_FETCHING_HOTEL_ROOM',
  RESET_FETCHING_HOTEL_ROOM: 'fetching/RESET_FETCHING_HOTEL_ROOM',
  STOP_FETCHING_HOTEL_ROOM: 'fetching/STOP_FETCHING_HOTEL_ROOM',

  START_FETCHING_VOUCHER: 'fetching/START_FETCHING_VOUCHER',
  STOP_FETCHING_VOUCHER: 'fetching/STOP_FETCHING_VOUCHER',


  START_FETCHING_TOUR: 'fetching/START_FETCHING_TOUR',
  STOP_FETCHING_TOUR: 'fetching/STOP_FETCHING_TOUR',
  START_FETCHING_TOUR_TICKET: 'fetching/START_FETCHING_TOUR_TICKET',
  STOP_FETCHING_TOUR_TICKET: 'fetching/STOP_FETCHING_TOUR_TICKET',
  START_FETCHING_TOUR_LINE: 'fetching/START_FETCHING_TOUR_LINE',
  STOP_FETCHING_TOUR_LINE: 'fetching/STOP_FETCHING_TOUR_LINE',

  START_FETCHING_CART: 'fetching/START_FETCHING_CART',
  STOP_FETCHING_CART: 'fetching/STOP_FETCHING_CART',

  START_FETCHING_ORDER: 'fetching/START_FETCHING_ORDER',
  STOP_FETCHING_ORDER: 'fetching/STOP_FETCHING_ORDER',

  START_FETCHING_LIST_ORDER: 'fetching/START_FETCHING_LIST_ORDER',
  STOP_FETCHING_LIST_ORDER: 'fetching/STOP_FETCHING_LIST_ORDER',

  START_FETCHING_COUPON: 'fetching/START_FETCHING_COUPON',
  STOP_FETCHING_COUPON: 'fetching/STOP_FETCHING_COUPON',

  START_FETCHING_VINWONDER: 'fetching/START_FETCHING_VINWONDER',
  STOP_FETCHING_VINWONDER: 'fetching/STOP_FETCHING_VINWONDER',

  START_FETCHING_AUTH: 'fetching/START_FETCHING_AUTH',
  STOP_FETCHING_AUTH: 'fetching/STOP_FETCHING_AUTH',
  START_FETCHING_AUTH_LOGIN_VPT: 'fetching/START_FETCHING_AUTH_LOGIN_VPT',
  STOP_FETCHING_AUTH_LOGIN_VPT: 'fetching/STOP_FETCHING_AUTH_LOGIN_VPT',
  SIGN_UP_VPT: 'fetching/SIGN_UP_VPT',
  SIGN_UP_VPT_SUCCESS: 'fetching/SIGN_UP_VPT_SUCCESS',
  SIGN_UP_VPT_ERROR: 'fetching/SIGN_UP_VPT_ERROR',
  LOGIN_VPT_ERROR: 'fetching/LOGIN_VPT_ERROR',
  SEND_OTP: 'fetching/SEND_OTP',
  SEND_OTP_ERROR: 'fetching/SEND_OTP_ERROR',
  RESET_SEND_OTP: 'fetching/RESET_SEND_OTP',
  CHECK_USER: 'fetching/CHECK_USER',
  CHECK_USER_SUCCESS: 'fetching/CHECK_USER_SUCCESS',
  CHECK_USER_ERROR: 'fetching/CHECK_USER_ERROR',
  FORGOT_PASSWORD: 'fetching/FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'fetching/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'fetching/FORGOT_PASSWORD_ERROR',
  UPDATE_USER: 'fetching/UPDATE_USER',
  UPDATE_USER_SUCCESS: 'fetching/UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'fetching/UPDATE_USER_ERROR',
  UPLOAD_AVATA: 'fetching/UPLOAD_AVATA',
  UPLOAD_AVATA_SUCCESS: 'fetching/UPLOAD_AVATA_SUCCESS',
  UPLOAD_AVATA_ERROR: 'fetching/UPLOAD_AVATA_ERROR',
  LINK_ACCOUNT: 'fetching/LINK_ACCOUNT',
  LINK_ACCOUNT_SUCCESS: 'fetching/LINK_ACCOUNT_SUCCESS',
  LINK_ACCOUNT_ERROR: 'fetching/LINK_ACCOUNT_ERROR',
  REMOVE_OLD_PHONE: 'fetching/REMOVE_OLD_PHONE',
  REMOVE_OLD_PHONE_SUCCESS: 'fetching/REMOVE_OLD_PHONE_SUCCESS',
  REMOVE_OLD_PHONE_ERROR: 'fetching/REMOVE_OLD_PHONE_ERROR',

  START_FETCHING_REGISTER: 'fetching/START_FETCHING_REGISTER',
  STOP_FETCHING_REGISTER: 'fetching/STOP_FETCHING_REGISTER',
  START_FETCHING_SEND_OTP: 'fetching/START_FETCHING_SEND_OTP',
  STOP_FETCHING_SEND_OTP: 'fetching/STOP_FETCHING_SEND_OTP',
  START_FETCHING_CHECK_USER: 'fetching/START_FETCHING_CHECK_USER',
  STOP_FETCHING_CHECK_USER: 'fetching/STOP_FETCHING_CHECK_USER',
  START_FETCHING_FORGOT_PWD: 'fetching/START_FETCHING_FORGOT_PWD',
  STOP_FETCHING_FORGOT_PWD: 'fetching/STOP_FETCHING_FORGOT_PWD',
  START_FETCHING_UPDATE_USER: 'fetching/START_FETCHING_UPDATE_USER',
  STOP_FETCHING_UPDATE_USER: 'fetching/STOP_FETCHING_UPDATE_USER',
  START_FETCHING_UPLOAD_AVATA: 'fetching/START_FETCHING_UPLOAD_AVATA',
  STOP_FETCHING_UPLOAD_AVATA: 'fetching/STOP_FETCHING_UPLOAD_AVATA',
  START_FETCHING_LINK_ACCOUNT: 'fetching/START_FETCHING_LINK_ACCOUNT',
  STOP_FETCHING_LINK_ACCOUNT: 'fetching/STOP_FETCHING_LINK_ACCOUNT',
  START_FETCHING_REMOVE_OLD_PHONE: 'fetching/START_FETCHING_REMOVE_OLD_PHONE',
  STOP_FETCHING_REMOVE_OLD_PHONE: 'fetching/STOP_FETCHING_REMOVE_OLD_PHONE',

  // navigation
  HISTORY_BACK: 'navigation/HISTORY_BACK',
  CHANGE_ROUTE: 'navigation/REFRESH_PAGE',

  // search
  CHANGE_SEARCH_FLIGHT_PARAMS: 'fetching/CHANGE_SEARCH_FLIGHT_PARAMS',
  CHANGE_SEARCH_HOTEL_PARAMS: 'fetching/CHANGE_SEARCH_HOTEL_PARAMS',
  CHANGE_SEARCH_HOMESTAY_PARAMS: 'fetching/CHANGE_SEARCH_HOMESTAY_PARAMS',
  CHANGE_SEARCH_BUNDLE_PARAMS: 'fetching/CHANGE_SEARCH_BUNDLE_PARAMS',

  // airport
  FETCHING_AIRPORT_SUCCESS: 'fetching/FETCHING_AIRPORT_SUCCESS',

  // flight
  FETCHING_FLIGHT_SEARCH_SUCCESS: 'fetching/FETCHING_FLIGHT_SEARCH_SUCCESS',
  FETCHING_FLIGHT_DETAIL_SUCCESS: 'fetching/FETCHING_FLIGHT_DETAIL_SUCCESS',
  FETCHING_FLIGHT_VERIFY_DETAIL_SUCCESS: 'fetching/FETCHING_FLIGHT_VERIFY_DETAIL_SUCCESS',
  FETCHING_FLIGHT_BAGGAGE_SUCCESS: 'fetching/FETCHING_FLIGHT_BAGGAGE_SUCCESS',
  FETCHING_FLIGHT_WEEKPRICES_SUCCESS: 'fetching/FETCHING_FLIGHT_WEEKPRICES_SUCCESS',
  FETCHING_FLIGHT_MONTH_SUCCESS: 'fetching/FETCHING_FLIGHT_MONTH_SUCCESS',

  // flight fare rule
  FETCHING_FLIGHT_FARERULE_SUCCESS: 'flight/FETCHING_FLIGHT_FARERULE_SUCCESS',
  FETCHING_HOLD_SUCCESS: 'flight/FETCHING_HOLD_SUCCESS',

  CHANGE_DEPARTURE_FLIGHT_SUCCESS: 'fetching/CHANGE_DEPARTURE_FLIGHT_SUCCESS',
  CHANGE_RETURN_FLIGHT_SUCCESS: 'fetching/CHANGE_RETURN_FLIGHT_SUCCESS',
  CHANGE_FLIGHT_SESSION: 'fetching/CHANGE_FLIGHT_SESSION',
  CHANGE_FILTER_FLIGHT: 'fetching/CHANGE_FILTER_FLIGHT',
  RESET_FILTER_FLIGHT: 'fetching/RESET_FILTER_FLIGHT',
  CHANGE_SORT_FLIGHT: 'fetching/CHANGE_SORT_FLIGHT',
  SWITCH_AIRPORT: 'fetching/SWITCH_AIRPORT',
  // order
  POST_ORDER_DOM_RESERVATION_SUCCESS:
    'fetching/FETCHING_ORDER_DOM_RESERVATION_SUCCESS',
  FETCHING_ORDER_DETAIL_SUCCESS: 'fetching/FETCHING_ORDER_DETAIL_SUCCESS',

  // payment
  FETCH_PAYMENT_SUCCESS: 'fetching/FETCH_PAYMENT_SUCCESS',
  SET_CONTACT_INFO_SUCCESS: 'fetching/SET_CONTACT_INFO_SUCCESS',

  // auth
  USER_LOGIN_SUCCESS: 'auth/USER_LOGIN_SUCCESS',
  USER_LOGOUT_SUCCESS: 'auth/USER_LOGOUT_SUCCESS',
  USER_REGISTER_SUCCESS: 'auth/USER_REGISTER_SUCCESS',
  USER_REFRESH_SUCCESS: 'auth/USER_REFRESH_SUCCESS',

  USER_FORGOT_PASSWORD_SUCCESS: 'auth/USER_FORGOT_PASSWORD_SUCCESS',
  USER_CHANGE_PASSWORD_SUCCESS: 'auth/USER_CHANGE_PASSWORD_SUCCESS',
  USER_VERIFY_EMAIL_SUCCESS: 'auth/USER_VERIFY_EMAIL_SUCCESS',
  USER_GET_PROFILE_SUCCESS: 'auth/USER_GET_PROFILE_SUCCESS',
  USER_UPDATE_PROFILE_SUCCESS: 'auth/USER_UPDATE_PROFILE_SUCCESS',

  REDIRECT_URL: 'redirect/REDIRECT_URL',
  CHECK_PEARL_CLUB: 'auth/CHECK_PEARL_CLUB',

  // UPDATE_CART
  UPDATE_CART: 'UPDATE_CART',
  UPDATE_CART_COUNT: 'UPDATE_CART_COUNT',
  UPDATE_COUPON: 'UPDATE_COUPON',

  FETCHING_ADD_TO_CART: 'cart/FETCHING_ADD_TO_CART',
  FETCHING_CART_DETAIL_ACTION: 'cart/FETCHING_CART_DETAIL_ACTION',
  FETCHING_CART_DETAIL_SUCCESS: 'cart/FETCHING_CART_DETAIL_SUCCESS',
  DELETE_CART_ITEM_SUCCESS: 'cart/DELETE_CART_ITEM_SUCCESS',
  UPDATE_CART_ITEM_SUCCESS: 'cart/UPDATE_CART_ITEM_SUCCESS',
  CHECKOUT_CART_SUCCESS: 'cart/CHECKOUT_CART_SUCCESS',
  CLEAR_CART_SUCCESS: 'cart/CLEAR_CART_SUCCESS',
  ADD_TO_CART_SUCCESS: 'cart/ADD_TO_CART_SUCCESS',
  CLEAR_ADD_TO_CART_NOTICE: 'cart/CLEAR_ADD_TO_CART_NOTICE',
  FETCHING_COUNT_CART_ITEM: 'cart/FETCHING_COUNT_CART_ITEM',

  USE_CREDIT_POINT: 'cart/USE_CREDIT_POINT',
  UNUSE_CREDIT_POINT: 'cart/UNUSE_CREDIT_POINT',
  START_FETCHING_CREDIT_POINT_CALCULATION: 'cart/START_FETCHING_CREDIT_POINT_CALCULATION',
  STOP_FETCHING_CREDIT_POINT_CALCULATION: 'cart/STOP_FETCHING_CREDIT_POINT_CALCULATION',
  SET_CREDIT_POINT_CALCULATION_DATA: 'cart/SET_CREDIT_POINT_CALCULATION_DATA',
  RESET_CREDIT_POINT_CALCULATION_DATA: 'cart/RESET_CREDIT_POINT_CALCULATION_DATA',

  //HOTEL
  CHANGE_FILTER_TYPE_STAY: 'hotel/CHANGE_FILTER_TYPE_STAY',
  CHANGE_FILTER_PRIORITY_REST_PLACE: 'hotel/CHANGE_FILTER_PRIORITY_REST_PLACE',
  RESET_HOTEL_FILTER: 'hotel/RESET_HOTEL_FILTER',
  CHANGE_SORT_HOTEL: 'hotel/CHANGE_SORT_HOTEL',

  // hotels
  FETCHING_HOTEL_LOCATION_SUCCESS: 'hotel/FETCHING_HOTEL_LOCATION_SUCCESS',
  FETCHING_HOTEL_SUCCESS: 'hotel/FETCHING_HOTEL_SUCCESS',
  FETCHING_ROOM_SUCCESS: 'hotel/FETCHING_ROOM_SUCCESS',
  FETCHING_ROOM_DETAIL_SUCCESS: 'hotel/FETCHING_ROOM_DETAIL_SUCCESS',
  FETCHING_HOTEL_ALL_LOCATION_SUCCESS: 'hotel/FETCHING_HOTEL_ALL_LOCATION_SUCCESS',
  FETCHING_HOTELS_BY_LOCATION_SUCCESS: 'hotel/FETCHING_HOTELS_BY_LOCATION_SUCCESS',
  FETCHING_HOTELS_DEALS_SUCCESS: 'hotel/FETCHING_HOTELS_DEALS_SUCCESS',
  FETCHING_PHS_ORDER_SUCCESS: 'hotel/FETCHING_PHS_ORDER_SUCCESS',

  CHANGE_HOTEL_SUCCESS: 'hotel/CHANGE_HOTEL_SUCCESS',
  BOOKING_CHECKOUT_SUCCESS: 'BOOKING_CHECKOUT_SUCCESS',
  SET_BOOKING_BUNDLE_ERROR: 'SET_BOOKING_BUNDLE_ERROR',
  REMOVE_PAYMENT_URL_SUCCESS: 'REMOVE_PAYMENT_URL_SUCCESS',
  SET_BOOKING_BUNDLE_PROMOTION_MARKUP_ERROR: 'SET_BOOKING_BUNDLE_PROMOTION_MARKUP_ERROR',

  BOOKING_HOTEL_SUCCESS: 'hotel/BOOKING_HOTEL_SUCCESS',
  COMMIT_HOTEL_SUCCESS: 'hotel/COMMIT_HOTEL_SUCCESS',

  PHS_ORDER_HOTEL_SUCCESS: 'hotel/PHS_ORDER_HOTEL_SUCCESS',
  PHS_ORDER_HOTEL_RELOAD: 'hotel/PHS_ORDER_HOTEL_RELOAD',
  PRICE_CHANGED_CONFIRM: 'hotel/PRICE_CHANGED_CONFIRM',
  HOTEL_MEMBER_RATE_SUCCESS: 'hotel/SUCCESS_HOTEL_MEMBER_RATE',
  HOTEL_CROSS_SALE_BOOKABLES_SUCCESS: 'hotel/HOTEL_CROSS_SALE_BOOKABLES_SUCCESS',
  RESET_COUNT_TIME_HOTEL_SUCCESS: 'hotel/RESET_COUNT_TIME_HOTEL_SUCCESS',

  // Combo
  FETCHING_BUNDLE_LOCATION_SUCCESS: 'bundle/FETCHING_BUNDLE_LOCATION_SUCCESS',
  FETCHING_BUNDLE_ALL_LOCATION_SUCCESS: 'bundle/FETCHING_BUNDLE_ALL_LOCATION_SUCCESS',
  FETCHING_BUNDLE_SEARCH_SUCCESS: 'bundle/FETCHING_BUNDLE_SEARCH_SUCCESS',
  FETCHING_BUNDLE_HOTEL_SUCCESS: 'bundle/FETCHING_BUNDLE_HOTEL_SUCCESS',
  FETCHING_BUNDLE_FLIGHT_SUCCESS: 'bundle/FETCHING_BUNDLE_FLIGHT_SUCCESS',
  FETCHING_BUNDLE_DETAIL_SUCCESS: 'bundle/FETCHING_BUNDLE_DETAIL_SUCCESS',
  FETCHING_BUNDLE_BESTRATE_SUCCESS: 'bundle/FETCHING_BUNDLE_BESTRATE_SUCCESS',
  SWITCH_POINTS: 'fetching/SWITCH_POINTS',
  FETCHING_COMBO_START: 'fetching/FETCHING_COMBO_START',
  FETCHING_COMBO_STOP: 'fetching/FETCHING_COMBO_STOP',
  SAVE_PATH_REDIRECT: 'combo/SAVE_PATH_REDIRECT',
  ADD_ROOMS_HOTEL_SUCCESS: 'combo/ADD_ROOMS_HOTEL_SUCCESS',
  RESET_COUNT_TIME_BUNDLE_SUCCESS: 'bundle/RESET_COUNT_TIME_BUNDLE_SUCCESS',
  RELEASE_COMBO_PHS: 'combo/RELEASE_COMBO_PHS',
  CHANGE_HOTEL_BUNDLE: 'bundle/CHANGE_HOTEL_BUNDLE',
  CHECK_CODE_BUNDLE: 'bundle/CHECK_CODE_BUNDLE',
  CHECK_CODE_BUNDLE_ERROR: 'bundle/CHECK_CODE_BUNDLE_ERROR',

  // orders
  START_FETCHING_RESEND_EMAIL: 'order/START_FETCHING_RESEND_EMAIL',
  STOP_FETCHING_RESEND_EMAIL: 'order/STOP_FETCHING_RESEND_EMAIL',
  FETCHING_ORDERS_SUCCESS: 'order/FETCHING_ORDERS_SUCCESS',
  FETCHING_ORDER_ITEM_SUCCESS: 'order/FETCHING_ORDER_SUCCESS',
  FETCHING_LIST_ORDER_ITEM_SUCCESS: 'order/FETCHING_LIST_ORDER_ITEM_SUCCESS',
  FETCHING_ORDER_INFO_SUCCESS: 'order/FETCHING_ORDER_INFO_SUCCESS',
  FETCHING_ORDER_FLIGHT_SUCCESS: 'order/FETCHING_ORDER_FLIGHT_SUCCESS',
  FETCHING_ORDER_HOTEL_SUCCESS: 'order/FETCHING_ORDER_HOTEL_SUCCESS',
  FETCHING_ORDER_VOUCHER_SUCCESS: 'order/FETCHING_ORDER_VOUCHER_SUCCESS',
  RESET_ORDERS_SUCCESS: 'order/RESET_ORDERS_SUCCESS',
  RESET_FETCHING_ORDER_ITEM_SUCCESS: 'order/RESET_FETCHING_ORDER_ITEM_SUCCESS',
  RESET_FETCHING_LIST_ORDER_ITEM_SUCCESS: 'order/RESET_FETCHING_LIST_ORDER_ITEM_SUCCESS',
  FETCHING_ORDER_SALE_REPORT_SUCCESS: 'order/FETCHING_ORDER_SALE_REPORT_SUCCESS',
  FETCHING_ORDER_TRACKING: 'order/FETCHING_ORDER_TRACKING',
  FETCHING_ORDER_CROSS_SELL_SUCCESS: 'order/FETCHING_ORDER_CROSS_SELL_SUCCESS',
  FETCHING_ORDER_CROSS_SELL_ERROR: 'fetching/FETCHING_ORDER_CROSS_SELL_ERROR,',

  // VoucherReview
  FETCHING_VOUCHER_GROUP_SUCCESS: 'voucher/FETCHING_VOUCHER_GROUP_SUCCESS',
  FETCHING_VOUCHER_SPECIAL_SUCCESS: 'voucher/FETCHING_VOUCHER_SPECIAL_SUCCESS',
  FETCHING_VOUCHER_DETAIL_SUCCESS: 'voucher/FETCHING_VOUCHER_DETAIL_SUCCESS',
  FETCHING_VOUCHER_LOCATION_SUCCESS: 'voucher/FETCHING_VOUCHER_LOCATION_SUCCESS',
  FETCHING_REVIEW_SUCCESS: 'voucher/FETCHING_REVIEW_SUCCESS',
  CREATE_REVIEW_SUCCESS: 'voucher/CREATE_REVIEW_SUCCESS',
  REPLY_REVIEW_SUCCESS: 'voucher/REPLY_REVIEW_SUCCESS',
  FETCHING_CATEGORY_VOUCHER_SUCCESS: 'voucher/FETCHING_CATEGORY_VOUCHER_SUCCESS',

  //Tour
  FETCHING_TOUR_SUCCESS: 'tour/FETCHING_TOUR_SUCCESS',
  FETCHING_TOUR_SPECIAL_SUCCESS: 'tour/FETCHING_TOUR_SPECIAL_SUCCESS',
  FETCHING_TOUR_DETAIL_SUCCESS: 'tour/FETCHING_TOUR_DETAIL_SUCCESS',
  FETCHING_TOUR_LINE_ACTION: 'tour/FETCHING_TOUR_LINE_ACTION',
  FETCHING_TOUR_LINE_SUCCESS: 'tour/FETCHING_TOUR_LINE_SUCCESS',
  CLEAR_TOUR_LINE_DATA: 'tour/CLEAR_TOUR_LINE_DATA',
  FETCHING_TOUR_TICKET_SUCCESS: 'tour/FETCHING_TOUR_TICKET_SUCCESS',
  FETCHING_TOUR_VINWONDER_TICKET_SUCCESS: 'tour/FETCHING_TOUR_VINWONDER_TICKET_SUCCESS',
  FETCHING_TOUR_STATE_PROVINCE_SUCCESS: 'tour/FETCHING_TOUR_STATE_PROVINCE_SUCCESS',
  FETCHING_TOUR_TYPE_SUCCESS: 'FETCHING_TOUR_TYPE_SUCCESS',

  //VinWonder
  FETCHING_VINWONDER_SUCCESS: 'voucher/FETCHING_VINWONDER_SUCCESS',
  FETCHING_VINWONDER_SPECIAL_SUCCESS: 'voucher/FETCHING_VINWONDER_SPECIAL_SUCCESS',
  FETCHING_VINWONDER_DETAIL_SUCCESS: 'voucher/FETCHING_VINWONDER_DETAIL_SUCCESS',
  FETCHING_VINWONDER_LOCATION_SUCCESS: 'voucher/FETCHING_VINWONDER_LOCATION_SUCCESS',
  FETCHING_CATEGORY_VINWONDER_SUCCESS: 'voucher/FETCHING_CATEGORY_VINWONDER_SUCCESS',
  FETCHING_CODE_VINWONDER_SUCCESS: 'voucher/FETCHING_CODE_VINWONDER_SUCCESS',
  FETCHING_TICKETS_VINWONDER_SUCCESS: 'voucher/FETCHING_TICKETS_VINWONDER_SUCCESS',
  FETCHING_SESSION_VINWONDER_SUCCESS: "FETCHING_SESSION_VINWONDER_SUCCESS",
  FETCHING_ZONE_VINWONDER_SUCCESS: "FETCHING_ZONE_VINWONDER_SUCCESS",
  FETCHING_IDENTITY_TYPE_VINWONDER_SUCCESS: "FETCHING_IDENTITY_TYPE_VINWONDER_SUCCESS",
  FETCHING_NATIONALITY_VINWONDER_SUCCESS: "FETCHING_NATIONALITY_VINWONDER_SUCCESS",

  // ExchangeRate
  START_FETCHING_EXCHANGERATE: 'voucher/START_FETCHING_EXCHANGERATE',
  STOP_FETCHING_EXCHANGERATE: 'voucher/STOP_FETCHING_EXCHANGERATE',
  FETCHING_EXCHANGERATE_SUCCESS: 'voucher/FETCHING_EXCHANGERATE_SUCCESS',

  //Banner
  FETCHING_PAGE_BANNER_SUCCESS: 'banner/FETCHING_PAGE_BANNER_SUCCESS',
  FETCHING_SITE_INFO_SUCCESS: 'cms/FETCHING_SITE_INFO_SUCCESS',
  FETCHING_IP_LOCALE_SUCCESS: 'cms/FETCHING_IP_LOCALE_SUCCESS',
  FETCHING_REDIRECT_LINK_SUCCESS: 'FETCHING_REDIRECT_LINK_SUCCESS',
  FETCHING_DOWNLOAD_APP_SUCCESS: 'FETCHING_DOWNLOAD_APP_SUCCESS',

  //BLog
  FETCHING_BLOG_POSTS_SUCCESS: 'blog/FETCHING_BLOG_POSTS_SUCCESS',
  FETCHING_BLOG_DETAIL_SUCCESS: 'blog/FETCHING_BLOG_DETAIL_SUCCESS',

  // Listing
  FETCHING_LISTING_SUCCESS: 'listing/FETCHING_LISTING_SUCCESS',
  FETCHING_LISTING_DETAIL_SUCCESS: 'listing/FETCHING_LISTING_DETAIL_SUCCESS',
  START_FETCHING_LISTING: 'fetching/START_FETCHING_LISTING',
  STOP_FETCHING_LISTING: 'fetching/STOP_FETCHING_LISTING',
  FETCHING_LISTING_STATE_PROVINCE_SUCCESS: 'listing/FETCHING_LISTING_STATE_PROVINCE_SUCCESS',
  FETCHING_LISTING_TYPE_SUCCESS: 'listing/FETCHING_LISTING_TYPE_SUCCESS',
  FETCHING_LISTING_LOCATION_SUCCESS: 'listing/FETCHING_LISTING_LOCATION_SUCCESS',
  FETCHING_CATEGORY_LISTING_SUCCESS: 'listing/FETCHING_CATEGORY_LISTING_SUCCESS',
  FETCHING_LISTING_SPECIAL_SUCCESS: 'voucher/FETCHING_LISTING_SPECIAL_SUCCESS',
  FETCHING_LISTING_RECOMMENDATION_SUCCESS: 'voucher/FETCHING_LISTING_RECOMMENDATION_SUCCESS',
  // FETCHING_LISTING_ORDER_ITEM_SUCCESS: 'voucher/FETCHING_LISTING_ORDER_ITEM_SUCCESS',
  FETCHING_LISTING_AUTOCOMPLETE_SUCCESS: 'listing/FETCHING_LISTING_AUTOCOMPLETE_SUCCESS',
  START_LISTING_AUTOCOMPLETE: 'listing/START_LISTING_AUTOCOMPLETE',
  RESET_LISTING_AUTOCOMPLETE: 'listing/RESET_LISTING_AUTOCOMPLETE',
  START_LISTING_SPECIAL: 'listing/START_LISTING_SPECIAL',
  STOP_LISTING_SPECIAL: 'listing/STOP_LISTING_SPECIAL',
  START_LISTING_RECOMMENDATION: 'listing/START_LISTING_RECOMMENDATION',
  STOP_LISTING_RECOMMENDATION: 'listing/STOP_LISTING_RECOMMENDATION',
  START_PRODUCT_RECOMMENDED: 'listing/START_PRODUCT_RECOMMENDED',
  STOP_PRODUCT_RECOMMENDED: 'listing/STOP_PRODUCT_RECOMMENDED',
  FETCHING_LISTING_BEST_SELLER_SUCCESS: 'voucher/FETCHING_LISTING_BEST_SELLER_SUCCESS',

  // Tag
  FETCHING_TAG_GROUP_SUCCESS: 'tag/FETCHING_TAG_GROUP_SUCCESS',
  FETCHING_MAPPING_TAG_SUCCESS: 'tag/FETCHING_MAPPING_TAG_SUCCESS',

  // Tag Hotel
  START_FETCHING_TAG_HOTEL: 'hotel/START_FETCHING_TAG_HOTEL',
  FETCHING_TAG_HOTEL_SUCCESS: 'hotel/FETCHING_TAG_HOTEL_SUCCESS',
  STOP_FETCHING_TAG_HOTEL: 'hotel/STOP_FETCHING_TAG_HOTEL',

  CHANGE_HOTEL_FILTER: 'hotel/CHANGE_HOTEL_FILTER',

  // Loyalty
  RESET_HISTORY_POINT_SUCCESS: 'loyalty/RESET_HISTORY_POINT_SUCCESS',
  FETCHING_HISTORY_POINT_SUCCESS: 'loyalty/FETCHING_HISTORY_POINT_SUCCESS',
  V_RESET_HISTORY_POINT_SUCCESS: 'loyalty/V_RESET_HISTORY_POINT_SUCCESS',
  V_FETCHING_HISTORY_POINT_SUCCESS: 'loyalty/V_FETCHING_HISTORY_POINT_SUCCESS',
  RESET_OFFER_SUCCESS: 'RESET_OFFER_SUCCESS',
  RESET_BENEFIT_SUCCESS: 'RESET_BENEFIT_SUCCESS',
  RESET_MY_BENEFIT_SUCCESS: 'RESET_MY_BENEFIT_SUCCESS',
  RESET_TRANSACTION_HISTORY_SUCCESS: 'loyalty/RESET_TRANSACTION_HISTORY_SUCCESS',
  FETCHING_TRANSACTION_HISTORY_SUCCESS: 'loyalty/FETCHING_TRANSACTION_HISTORY_SUCCESS',
  FETCHING_OFFER_SUCCESS: 'loyalty/FETCHING_OFFER_SUCCESS',
  FETCHING_BENEFIT_SUCCESS: 'FETCHING_BENEFIT_SUCCESS',
  FETCHING_MY_BENEFIT_SUCCESS: 'FETCHING_MY_BENEFIT_SUCCESS',
  REGISTER_LOYALTY_SUCCESS: 'REGISTER_LOYALTY_SUCCESS',
  FETCHING_REGISTER_LOYALTY_SUCCESS: 'FETCHING_REGISTER_LOYALTY_SUCCESS',
  SET_ACCUMULATED_BALANCE: 'SET_ACCUMULATED_BALANCE',
  RESET_FAQ_SUCCESS: 'RESET_FAQ_SUCCESS',
  FETCHING_FAQ_SUCCESS: 'FETCHING_FAQ_SUCCESS',
  RESET_COLLABORATORS_SUCCESS: 'RESET_COLLABORATORS_SUCCESS',
  FETCHING_COLLABORATORS_SUCCESS: 'FETCHING_COLLABORATORS_SUCCESS',

  // Aminitrative Divisions
  FETCHING_AMINITRATIVE_DIVISION_DATA: 'FETCHING_AMINITRATIVE_DIVISION_DATA',

  // Insider - Get best price hotel
  FETCHING_BEST_PRICE_DATA: 'FETCHING_BEST_PRICE_DATA',

  PROMOTION_DATA_NO_FILL_SUCCESS: 'PROMOTION_DATA_NO_FILL_SUCCESS',

  FETCHING_PROMOTION_DATA_FILL_SUCCESS: 'FETCHING_PROMOTION_DATA_FILL_SUCCESS',
  START_FETCHING_PROMOTION_DATA_FILL: 'START_FETCHING_PROMOTION_DATA_FILL',
  STOP_FETCHING_PROMOTION_DATA_FILL: 'STOP_FETCHING_PROMOTION_DATA_FILL',
  FETCHING_PROMOTION_DATA_PROMOTION_BANK_SUCCESS: 'FETCHING_PROMOTION_DATA_PROMOTION_BANK_SUCCESS',

  FETCHING_PROMOTION_COUPON_LOYALTY_SUCCESS: 'FETCHING_PROMOTION_COUPON_LOYALTY_SUCCESS',
  MY_OFFER_PROMOTION_CODE_SKU: 'MY_OFFER_PROMOTION_CODE_SKU',

  //footer
  FOOTER_URL_NAV_LINK: 'footer/FOOTER_URL_NAV_LINK',

  //currency
  CURRENCY_RATE_GET_FROM_WEB: 'CURRENCY_RATE_GET_FROM_WEB',

  // homestay
  START_FETCHING_HOMESTAY: 'fetching/START_FETCHING_HOMESTAY',
  STOP_FETCHING_HOMESTAY: 'fetching/STOP_FETCHING_HOMESTAY',
  START_FETCHING_HOMESTAY_ROOM: 'fetching/START_FETCHING_HOMESTAY_ROOM',
  STOP_FETCHING_HOMESTAY_ROOM: 'fetching/STOP_FETCHING_HOMESTAY_ROOM',
  RESET_FETCHING_HOMESTAY_ROOM: 'fetching/RESET_FETCHING_HOMESTAY_ROOM',

  FETCHING_HOMESTAY_SUCCESS: 'homestay/FETCHING_HOMESTAY_SUCCESS',
  FETCHING_HOMESTAY_ROOM_SUCCESS: 'homestay/FETCHING_HOMESTAY_ROOM_SUCCESS',
  FETCHING_HOMESTAY_ALL_LOCATION_SUCCESS: 'homestay/FETCHING_HOMESTAY_ALL_LOCATION_SUCCESS',
  FETCHING_HOMESTAYS_BY_LOCATION_SUCCESS: 'homestay/FETCHING_HOMESTAYS_BY_LOCATION_SUCCESS',
  FETCHING_HOMESTAYS_DEALS_SUCCESS: 'homestay/FETCHING_HOMESTAYS_DEALS_SUCCESS',
  FETCHING_PHS_ORDER_HOMESTAY_SUCCESS: 'homestay/FETCHING_PHS_ORDER_HOMESTAY_SUCCESS',

  BOOKING_HOMESTAY_SUCCESS: 'homestay/BOOKING_HOMESTAY_SUCCESS',
  COMMIT_HOMESTAY_SUCCESS: 'homestay/COMMIT_HOMESTAY_SUCCESS',

  RESET_HOMESTAY_FILTER: 'homestay/RESET_HOMESTAY_FILTER',
  CHANGE_SORT_HOMESTAY: 'homestay/CHANGE_SORT_HOMESTAY',
  CHANGE_HOMESTAY_SUCCESS: 'homestay/CHANGE_HOMESTAY_SUCCESS',

  PHS_ORDER_HOMESTAY_SUCCESS: 'homestay/PHS_ORDER_HOMESTAY_SUCCESS',
  PHS_ORDER_HOMESTAY_RELOAD: 'homestay/PHS_ORDER_HOMESTAY_RELOAD',
  HOMESTAY_MEMBER_RATE_SUCCESS: 'homestay/SUCCESS_HOMESTAY_MEMBER_RATE',

  START_FETCHING_TAG_HOMESTAY: 'homestay/START_FETCHING_TAG_HOMESTAY',
  FETCHING_TAG_HOMESTAY_SUCCESS: 'homestay/FETCHING_TAG_HOMESTAY_SUCCESS',
  STOP_FETCHING_TAG_HOMESTAY: 'homestay/STOP_FETCHING_TAG_HOMESTAY',

  CHANGE_HOMESTAY_FILTER: 'homestay/CHANGE_HOMESTAY_FILTER',
  SET_HOMESTAY_PAGE: 'homestay/SET_HOMESTAY_PAGE',

  // vietjet
  START_FETCHING_INTERNATIONAL_NAME: 'fetching/START_FETCHING_INTERNATIONAL_NAME',
  STOP_FETCHING_INTERNATIONAL_NAME: 'fetching/STOP_FETCHING_INTERNATIONAL_NAME',
  FETCHING_INTERNATIONAL_NAME_SUCCESS : 'vietjet/INTERNATIONAL_NAME_SUCCESS',
  FETCHING_INTERNATIONAL_NAME_FAILS : 'vietjet/INTERNATIONAL_NAME_FAILS',
}


export default actionTypes
