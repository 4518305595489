// MUST be use "require" instead of "import", "module.exports" instead of "export" in this file
const cookie = require('js-cookie')

const STORAGE_KEY = {
    ACCEPT_COOKIES: 'hms_accept_cookie',
    PREFIX: 'hms_vpt',
    AUTH: 'auth',
    PROFILE: 'user',
    LANGUAGE: 'lang',
    CURRENCY: 'currency',
    CURRENCYONL: 'currencyOnl',
    AUTH0_AUTH: 'hms_auth0_auth',
    AUTH0_AUTH_REFRESH: 'hms_auth0_auth_refresh',
}

const getKeyWithPrefix = (key) => `${STORAGE_KEY.PREFIX}_${key}`

const get = (key, withoutPrefix = false, options = undefined) => {
    const storageKey = withoutPrefix ? key : getKeyWithPrefix(key);
    const stringData = cookie.get(storageKey, options);
    const numberRegex = RegExp('^[1-9.]+$');
    if (stringData === 'true' || stringData === 'false') {
        return stringData === 'true'
    } else if (stringData === 'null') {
        return null;
    } else if (typeof stringData === 'undefined') {
        return undefined;
    } else if (numberRegex.test(stringData)) {
        return Number(stringData)
    } else {
        try {
            const data = JSON.parse(stringData);
            return data;
        } catch (error) {
            return stringData
        }
    }
}

const setAcceptCookie = () => {
    cookie.set(STORAGE_KEY.ACCEPT_COOKIES, 'true', {
        expires: 365,
        secure: true,
    })
}

const set = (key, data, options, withoutPrefix = false) => {
    const storageKey = withoutPrefix ? key : getKeyWithPrefix(key);

    const acceptCookieValue = cookie.get('hms_accept_cookie');
    if (storageKey !== 'hms_accept_cookie' && acceptCookieValue !== 'true') {
        return;
    }

    let stringData = undefined;
    try {
        if (typeof data === 'object') {
            stringData = JSON.stringify(data)
        } else {
            stringData = String(data);
        }
    } catch (error) {
        stringData = data
    }
    return cookie.set(storageKey, stringData, options);
}

const remove = (key, options, withoutPrefix) => cookie.remove(withoutPrefix ? key : getKeyWithPrefix(key), options)

module.exports = {
    STORAGE_KEY,
    getKeyWithPrefix,
    setAcceptCookie,
    get, set, remove,
}