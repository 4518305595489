import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import hotelAction from '../actions/hotelAction'

export const loadHotelLocation = () => {
  const dispatch = useDispatch()

  const loadHotelLocationCallback = useCallback(
    (keyword) => dispatch(hotelAction.getHotelLocations(keyword)),
    [dispatch]
  )

  return [loadHotelLocationCallback]
}

export const loadHotelsByLocation = () => {
  const dispatch = useDispatch()

  const loadHotelsByLocationCallback = useCallback(
    (params) => dispatch(hotelAction.getHotelByLocations(params)),
    [dispatch]
  )

  return [loadHotelsByLocationCallback]
}

export const changeTypeStayFilter = () => {
  const dispatch = useDispatch()

  const changeTypeStayFilterCallback = useCallback(
    (value) => dispatch(hotelAction.changeHotelTypeStayFilter(value)),
    [dispatch]
  )

  return [changeTypeStayFilterCallback]
}

export const changeHotelFilter = () => {
  const dispatch = useDispatch()

  const changeHotelFilterCallback = useCallback(
    (values) => dispatch(hotelAction.changeHotelFilter(values)),
    [dispatch]
  )

  return [changeHotelFilterCallback]
}

export const changeRestPlaceFilter = () => {
  const dispatch = useDispatch()

  const changeRestPlaceFilterCallback = useCallback(
    (value) => dispatch(hotelAction.changeHotelRestPlaceFilter(value)),
    [dispatch]
  )

  return [changeRestPlaceFilterCallback]
}

export const resetHotelFilter = () => {
  const dispatch = useDispatch()

  const resetFilterCallback = useCallback(
    (value) => dispatch(hotelAction.resetHotelFilter(value)),
    [dispatch]
  )

  return [resetFilterCallback]
}

export const changeSortDirection = () => {
  const dispatch = useDispatch()

  const changeSortDirectionCallback = useCallback(
    (sortDirection) => dispatch(hotelAction.changeSortHotel(sortDirection)),
    [dispatch]
  )

  return [changeSortDirectionCallback]

}

export const loadHotels = () => {
  const dispatch = useDispatch()

  const loadHotelCallback = useCallback(
    (queryClause) => dispatch(hotelAction.getHotels(queryClause)),
    [dispatch]
  )

  return [loadHotelCallback]
}

export const loadRoomsCallback = () => {
  const dispatch = useDispatch()

  return useCallback(
    (hotelId) => dispatch(hotelAction.getRooms(hotelId)),
    [dispatch]
  )

}

export const loadRoomDetail = () => {
  const dispatch = useDispatch()

  const loadRoomDetailCallback = useCallback(
    (data) => dispatch(hotelAction.getRoom(data)),
    [dispatch]
  )

  return [loadRoomDetailCallback]
}

export const changeHotel = () => {
  const dispatch = useDispatch()

  const changeHotelCallback = useCallback(
    (hotel) => dispatch(hotelAction.changeHotel(hotel)),
    [dispatch]
  )

  return [changeHotelCallback]
}

export const postBookingHotel = () => {
  const dispatch = useDispatch()

  const postBookingHotelCallback = useCallback(
    (data) => dispatch(hotelAction.bookingHotel(data)),
    [dispatch]
  )
  return [postBookingHotelCallback]
}

export const removePaymentUrlSuccess = () => {
  const dispatch = useDispatch()

  return useCallback(
    () => dispatch(hotelAction.removePaymentUrl()),
    [dispatch]
  )
}

export const loadHotel3s = () => {
  const dispatch = useDispatch()

  const loadHotels3sCallback = useCallback(
    (data, intl) => dispatch(hotelAction.getHotels3s(data, intl)),
    [dispatch]
  )

  return [loadHotels3sCallback]
}

export const loadDetailHotel3s = () => {
  const dispatch = useDispatch()

  return useCallback(
    (hotelData, callBackFn) => dispatch(hotelAction.getDetailHotel3s(hotelData, callBackFn)),
    [dispatch]
  )

}

export const getHotelFilter = () => {
  const dispatch = useDispatch()

  const getFilterCallback = useCallback(
    () => dispatch(hotelAction.getHotelFilter()),
    [dispatch]
  )

  return [getFilterCallback]
}

export const getParamsHotel3s = () => {
  const dispatch = useDispatch()

  return useCallback(
    (hotelCode, anotherParams) => dispatch(hotelAction.getParamsHotel3s(hotelCode, anotherParams)),
    [dispatch]
  )

}

export const phsOrderHotel = () => {
  const dispatch = useDispatch()
  const phsOrderHotelCallback = useCallback(
    (data, executeRecaptcha, paramsRedirect = undefined) => dispatch(hotelAction.PHSOrderHotel(data, executeRecaptcha, paramsRedirect)),
    [dispatch]
  )
  return [phsOrderHotelCallback]
}

export const loadHotelMemberRateCallback = () => {
  const dispatch = useDispatch()

  return useCallback(
    (params, callBackFn) => dispatch(hotelAction.getHotelMemberRate(params, callBackFn)),
    [dispatch]
  )
}

export const loadCrossSaleBookablesCallback = () => {
  const dispatch = useDispatch()

  return useCallback(
    (params, callBackFn) => dispatch(hotelAction.getCrossSaleBookables(params, callBackFn)),
    [dispatch]
  )
}

export const getListHotelDeals = () => {
  const dispatch = useDispatch()
  return useCallback(
    () =>
      dispatch(hotelAction.getListHotelDeals()),
    [dispatch]
  )
}

export const resetCountTimePhsHotel = () => {
  const dispatch = useDispatch()
  const resetCountTimePhsHotelCallback = useCallback(
    (queryClause) => dispatch(hotelAction.resetCountTimePhsHotel(queryClause)),
    [dispatch]
  )
  return [resetCountTimePhsHotelCallback]
}