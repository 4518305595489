import React from 'react'
import { FormattedMessage } from 'react-intl.macro'
import FLAGS from './featureFlags'
import moment from 'moment'
import { getMomentDate, getDatesArray } from '../utils/common'
import { INSTALLMENT_POLICY_PAGE_SLUG } from './nav'
import { currencyPath } from './currencyUnit'
import routes from './routes'

export { default as routes } from './routes'
export { default as enumType } from './enumType'
export { default as nav } from './nav'
export { default as topNav } from './topNav'
export { default as socialNetworks } from './socialNetworks'
export { default as validateError } from './validateError'
export { default as featureFlags } from './featureFlags'

// format datetime
export const DEFAULT_FORMAT_DATE = 'MM/DD/YYYY'
export const FORMAT_ONLY_HOUR = 'HH:mm'
export const FORMAT_ONLY_DATE = 'DD/MM/YYYY'
export const FORMAT_ONLY_DATE_3S = 'YYYY/MM/DD'
export const FORMAT_ONLY_DATE_DOT = 'DD.MM.YYYY'
export const FORMAT_DATE = 'DD-MM-YYYY'
export const FORMAT_ONLY_DATE_REVERSE = 'YYYY-MM-DD'
export const FORMAT_ONLY_DATE_EN = 'MM/DD/YYYY'
export const FORMAT_ONLY_MONTH_STRING = 'MMMM, YYYY'
export const FORMAT_ONLY_MONTH = 'MM/YYYY'
export const FORMAT_MONTH = 'MM-YYYY'
export const FORMAT_DATE_TIME = 'HH:mm DD/MM/YYYY'
export const FORMAT_DATE_TIME_3S = 'hh:mm | DD/MM/YYYY'
export const FORMAT_DATE_TIME_AFTER = 'DD/MM/YYYY HH:mm'
export const FORMAT_DATE_TIME_AFTER_2 = 'DD/MM/YYYY - HH:mm'
export const FORMAT_DAY = 'dddd DD/MM/YYYY'
export const FORMAT_DAY_COMMA = 'dddd, DD/MM/YYYY'
export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000'
export const GUID_SAME_PRICE = '00000000-0000-0000-0000-000000000001'
export const DATE_RANGE = 7
export const MAX_PASSENGER_QUANTITY = 9
export const MINIMUM_YEAR = 1970

export const MAX_AVAILABLE_ROOM = 5
export const ROOM_RATE_SHOW = 2
export const ROOM_RATE_BUNDLE_SHOW = 3
export const ROOM_AMENITY_SHOW = 4
export const AMENITY_GENERAL_SHOW = 8
export const MAX_COMMENT_CHARACTER = 500
export const MAX_VOUCHER_TAG = 3
export const MAX_VOUCHER_COUNTDOWN_DAY = 15
export const VOUCHER_SLIDE_SHOW = 3
export const MAX_VOUCHER_TITLE_LINE = 2
export const MAX_BLOG_DESCRIPTION_LINE = 2
export const MAX_SALE_REMAIN_DAYS = 15;
export const PHONE_REGEX = /(84|0[3|5|7|8|9])+([0-9]{8,10})\b/g;
export const PHONE_REGEX_2 = /^\+\d+$/;
export const PHONE_REGEX_3 = /^\+84\d{9}$/;
export const EMAIL_REGEX = /^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/;

export const FORMAT_PHONE = (value) => {
  let inputValue = value;
  if (/^0\d{9}$/.test(inputValue)) {
    inputValue = '+84' + inputValue.substring(1);
  } else if (/^84\d{9}$/.test(inputValue)) {
    inputValue = '+' + inputValue;
  } else if (/^\+840\d{9}$/.test(inputValue)) {
    inputValue = '+84' + inputValue.substring(4);
  } else if (/^840\d{9}$/.test(inputValue)) {
    inputValue = '+84' + inputValue.substring(3);
  }
  return inputValue;
};

export const HIDE_VALUE = (value) => {
  if (PHONE_REGEX_2.test(value)) {
    if (value.length > 4){
      const hiddenCharacters = value.length - 4;
      return '*'.repeat(hiddenCharacters) + value.slice(-4);
    } else return value
  } else if (EMAIL_REGEX.test(value)){
    const atIndex = value.indexOf('@');
    if (atIndex >= 2) {
        const username = value.substring(0, 2) + '*'.repeat(atIndex - 2);
        const lastString = value.substring(atIndex);
        return username + lastString;
    }
  } else if (value.length > 5){
      return '*'.repeat(value.length - 4) + value.slice(-4);
  } else {
      return '*'.repeat(value);
  }
  return value;
}

export const TOKEN_TIMEOUT = 5 * 60 * 1000

export const VIETNAMESE_CURRENCY_UNIT = 'đ'

export const HOTEL_MAX_STAR = 5

export const MAX_STAR = 5

export const MAX_ROOM_BOOKING = 9

export const MAX_PASSENGER_COMBO_BOOKING = 8

export const MAX_PASSENGER_BOOKING = 9

export const MAX_COUNT_PRODUCT_TAG = 3

export const CHILD_OCCUPANCY_CODE = 'CHILD'
export const INFANT_OCCUPANCY_CODE = 'INFANT'
export const DEFAULT_PAYMENT_TYPE = 'other'
export const DEFAULT_PAYMENT_TYPE_WITH_CREDIT_POINT = 'CreditPoint'
export const VPT_PAYMENT_MERCHANT_CODE = 'VPT_BH_1';

export const PageToShowLoginPopups = [
  "hotel/search",
  "hotel/",
  "hotel/add-on-services",
  "hotel/booking",
  "cross-sell/",
  "cross-sell/booking",
  "staynfun/",
  "staynfun/search",
  "staynfun/booking",
]

export const ELEMENT_IDS = {
  PRODUCT_REVIEW_SECTION_ID: 'product-review-section',
  VINWONDER_BOOKING_SECTION: 'vinwonder-booking-section',
  COMBO_ROOMTYPE_SECTION: 'combo-room-types-section',
  COMBO_CURRENT_SELECT_FLIGHT: 'combo-current-select-flight',
  TOUR_BOOKING_SECTION: 'tour-booking-section',
  VOUCHER_BOOKING_SECTION: 'voucher-booking-section',
  STICKY_TABS_SECTION: 'sticky-tabs-section',
}

export const LOADING_MESSAGE = <FormattedMessage
  id="LoadingMessage"
  defaultMessage='Đang xử lý giao dịch, vui lòng không tắt trình duyệt'
/>

//memberShipTypes
export const MEMBER_SHIP_TYPE_TOUR = {
  NON: { id: 6, value: 'NON', key: 6, label: 'NON' },
  PEARL: { id: 7, value: 'PEARL', key: 7, label: 'Pearl' },
  AMBASSADOR_PEARL: { id: 8, value: 'AMBASSADOR_PEARL', key: 8, label: 'AmPearl' },
  PEARL_CLUB: { id: 5, value: 'PEARL_CLUB', key: 5, label: 'VipPearl' }
}

// paging
export const DEFAULT_PAGE_SIZE = 8
export const DEFAULT_PAGE_INDEX = 1

export const DEFAULT_WHITE_SPACE = 40

export const POSITION_BANNER = {
  top: 1,
  bottom: 2
}

export const ALL_LOCATION = 'all'
export const ALL_PRICE = 'all'

export const FORM_ITEM_HORIZONTAL_LAYOUT = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

export const TAIL_FORM_ITEM_HORIZONTAL_LAYOUT = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
}

export const OrderStatus = {
  Failed: {
    id: 1,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.Status.Failed"
      defaultMessage='Không thành công'
    />,
    color: 'red',
  },
  Success: {
    id: 2,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.Status.Success"
      defaultMessage='Thành công'
    />,
    color: 'green',
  },
  PartiallyCancelled: {
    id: 5,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.Status.PartiallyCancelled"
      defaultMessage='Đã hủy 1 phần'
    />,
    color: 'volcano',
  },
  Cancelled: {
    id: 6,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.Status.Cancelled"
      defaultMessage='Đã hủy'
    />,
    color: 'red',
  },
  PartiallyRefunded: {
    id: 7,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.Status.PartiallyRefunded"
      defaultMessage='Đã hoàn 1 phần'
    />,
    color: 'volcano',
  },
  Refunded: {
    id: 8,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.Status.Refunded"
      defaultMessage='KĐã hoàn'
    />,
    color: 'volcano',
  },
  Waiting: {
    id: 9,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.Status.Waiting"
      defaultMessage='Chờ xử lý'
    />,
    color: 'volcano',
  },
}

export const PaymentStatus = {
  Unpaid: {
    id: 1,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.PaymentStatus.Unpaid"
      defaultMessage='Chưa thanh toán'
    />,
    color: 'red',
  },
  Paid: {
    id: 2,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.PaymentStatus.Paid"
      defaultMessage='Đã thanh toán'
    />,
    color: 'green',
  },
  Pending: {
    id: 3,
    label: <FormattedMessage
      id="MyOrder.OrderManagement.TableOrder.PaymentStatus.Pending"
      defaultMessage='Chờ thanh toán'
    />,
    color: 'volcano',
  },
}

export const OrderItemStatus = {
  Published: 1,
  Sold: 2,
  Used: 3,
  Refunded: 4,
  Cancelled: 5,
  Failed: 13,
}

export const VoucherStatus = {
  released: 1,
  cardAssigned: 2,
  exported: 3,
  sold: 4,
  gotten: 5,
  using: 6,
  used: 7,
  confronted: 8,
  expired: 999,
  locked: 998
}

export const OrderItemType = {
  voucher: {
    key: 1,
    value: 'Voucher'
  },

  tour: {
    key: 2,
    value: 'Tour'
  },
  flight: {
    key: 3,
    value: <FormattedMessage
      id="OrderItemType.Flight"
      defaultMessage='Chuyến bay'
    />
  },
  hotel: {
    key: 4,
    value: <FormattedMessage
      id="OrderItemType.Hotel"
      defaultMessage='Khách sạn'
    />
  },
  vinWonderTicket: {
    key: 5,
    value: <FormattedMessage
      id="OrderItemType.VinWonderTicket"
      defaultMessage='Vé VinWonders'
    />
  },
}

export const genderType = {
  Women: 0,
  Man: 1
}
export const CONTACT_INFO = {
  HOTLINE: '1900 23 23 89',
  HOTLINE_BRANCH: 'Nhánh số 3',
  HOTLINE_PRICE: '1000 VNĐ/phút',
  EMAIL: 'callcenter@vinpearl.com',
  ADDRESS_BUILDING: 'Tòa nhà Symphony',
  ADDRESS_LINE1: 'Đường Chu Huy Mân, Vinhomes Riverside Long Biên, Hà Nội, Việt Nam',
  ADDRESS_LINE2: 'Phường Việt Hưng, Quận Long Biên, Hà Nội',
  FACEBOOK_FANPAGE: 'https://www.facebook.com/Vinpearl',
  YOUTUBE_CHANNEL: 'https://www.youtube.com/channel/UC2tvH7ONGc-UTyEeVg-ojfQ',
  INSTAGRAM: 'https://www.instagram.com/vinpearl.official/?hl=vi',
}

export const ACCESSTRADE = {
  SCRIPT_SRC: '//static.accesstrade.vn/js/trackingtag/tracking.min.js',
  CAMPAIGN_ID: 751,
  IS_REOCCUR: 1,
  IS_LASTCLICK: 1
}

export const INSIDER = {
  PartnerName: 'vinpearlvn', //vinpearlvn.api.useinsider.com/ins.js?id=10004592
  PartnerId: 10004592,
  InsiderObject: {
    Page: { Type: { Confirmation: 'Confirmation' } }
  }
}

export const LOCAL_STORAGE_KEY = {
  PREFIX: 'HMS_LS:',
  ORDER_PURCHASE_DATA: 'ORDER_PURCHASE_DATA',
  CART_CHECKED_ITEM: 'CART_CHECKED_ITEM',
  PAYMENT_CONTACT_INFO: 'PAYMENT_CONTACT_INFO',
  SEARCH_FLIGHT_PARAMS: 'SEARCH_FLIGHT_PARAMS',
  CLOSED_STICKY_ADS: 'CLOSED_STICKY_ADS:',
  HOTEL_PARAMS_RELOAD: 'HOTEL_PARAMS_RELOAD',
  HOMESTAY_PARAMS_RELOAD: 'HOMESTAY_PARAMS_RELOAD',
  DATA_ROOM_BOOKING: 'DATA_ROOM_BOOKING',
  DATA_ROOM_WITH_CROSS_SELL: 'DATA_ROOM_WITH_CROSS_SELL',
  DATA_STAYNFUN_ROOM_BOOKING: 'DATA_STAYNFUN_ROOM_BOOKING',
  INFO_PROFILE_BOOKING: 'INFO_PROFILE_BOOKING',
  INFO_PROFILE_CHECKOUT: 'INFO_PROFILE_CHECKOUT',
  DATA_ROOM_BUNDLE_BOOKING: 'DATA_ROOM_BUNDLE_BOOKING',
  INFO_PROFILE_IS_1ST_BOOKER: 'INFO_PROFILE_IS_1ST_BOOKER',
  SEARCH_FLIGHT_FILTERS: 'SEARCH_FLIGHT_FILTERS',
  LANGUAGE_WESBITE: 'LANGUAGE_WESBITE',
  CURRENCY_WESBITE: 'CURRENCY_WESBITE',
  PHS_ORDER_HOTEL: 'PHS_ORDER_HOTEL',
  PHS_ORDER_BUNDLE: 'PHS_ORDER_BUNDLE',
  PHS_ORDER_HOMESTAY: 'PHS_ORDER_HOMESTAY',
  SAVE_PASSWORD: 'SAVE_PASSWORD',
  CHECK_PEARL_CLUB_SUCCESS: 'CHECK_PEARL_CLUB_SUCCESS',
  ACCUMULATE_BALANCE: 'ACCUMULATE_BALANCE',
  PHS_ORDER_HOTEL_IS_SUBMIT: 'PHS_ORDER_HOTEL_IS_SUBMIT',
  PHS_ORDER_HOTEL_ID: 'PHS_ORDER_HOTEL_ID',

  // GA360 TransactionData
  GA360_ORDER_FLIGHT: "GA360_ORDER_FLIGHT",
  BUNDLE_BOOKING_DATA: "BUNDLE_BOOKING_INFO",
  BUNDLE_COMBO_INFO: "BUNDLE_COMBO_INFO",
  HOTEL_PAYMENT_DATA: "HOTEL_PAYMENT_DATA",
  STAYNFUN_PAYMENT_DATA: "STAYNFUN_PAYMENT_DATA",
  URL_PARAM: "URL_PARAM",
  GA360_ORDER_SKU: "GA360_ORDER_SKU",
  MIN_PRICE_COMBO: "MIN_PRICE_COMBO",
  GA360_COMBO_CONTACT_INFO: "GA360_COMBO_CONTACT_INFO",
  GA360_COMBO_HOTEL: "GA360_COMBO_HOTEL",
  AUTO_FILL_PROMOTION: "AUTO_FILL_PROMOTION",
  API_SUPPORT_PROMOTION_CODE: "API_SUPPORT_PROMOTION_CODE",
  API_SUPPORT_PROMOTION_CODE_HOMESTAY: "API_SUPPORT_PROMOTION_CODE_HOMESTAY",
  URL_PARAM_COMBO: "URL_PARAM_COMBO",
  INSIDER: {
    URL_COMBO: "INSIDER_URL_COMBO"
  }
}

export const PROMOS = {
  VOUCHER: [
    {
      identity: (voucher) => {
        const name = (voucher ? (voucher.name || '') : '').toLowerCase();
        return [
          'flash sale',
          'flashsale',
          'flash_sale',
          'flash-sale'
        ].some(prefix => name.indexOf(prefix) >= 0)
      },
      getNotReadyForSaleText: (voucher) => {
        const isStarted = moment().isAfter(moment(voucher.startDate))
        const isEnded = moment().isAfter(moment(voucher.endDate));
        const isRunning = moment().isBetween(getMomentDate(voucher.saleStartDate), getMomentDate(voucher.saleEndDate), null, '[]')
        const runningDates = getDatesArray(
          getMomentDate(voucher.saleStartDate).toDate(),
          getMomentDate(voucher.saleEndDate).toDate()
        )
        const runningDate = runningDates.find(date => date.getDate() >= new Date().getDate())
        const fromTime = voucher.saleStartDate && getMomentDate(voucher.saleStartDate).format('HH')
        const toTime = voucher.saleEndDate && getMomentDate(voucher.saleEndDate).format('HH')
        return isStarted && !isEnded && !isRunning && !!runningDate && fromTime && toTime ? (
          <FormattedMessage
            id="Voucher.Search.TicketInfo.PromoDateNotReadyForSaleFromTo"
            defaultMessage='Mở bán từ {from} - {to} ngày {date}'
            values={{
              date: moment(runningDate).format('DD/MM'),
              from: fromTime + 'h',
              to: toTime + 'h'
            }}
          />
        ) : undefined
      },
    }
  ]
}

export const SHOPPING_CART_ICON = <svg width={24} height={24} style={{ fill: '#000000' }}>
  <g id="Shopping_Cart">
    <g>
      <g>
        <g>
          <path d="M18.5,23c-1.378,0-2.5-1.122-2.5-2.5s1.122-2.5,2.5-2.5s2.5,1.122,2.5,2.5S19.878,23,18.5,23z M18.5,19
					c-0.827,0-1.5,0.673-1.5,1.5s0.673,1.5,1.5,1.5s1.5-0.673,1.5-1.5S19.327,19,18.5,19z"/>
        </g>
        <g>
          <path d="M8.5,23C7.122,23,6,21.878,6,20.5S7.122,18,8.5,18s2.5,1.122,2.5,2.5S9.878,23,8.5,23z M8.5,19
					C7.673,19,7,19.673,7,20.5S7.673,22,8.5,22s1.5-0.673,1.5-1.5S9.327,19,8.5,19z"/>
        </g>
        <g>
          <path d="M21.5,17H8.959c-1.662,0-3.106-1.183-3.432-2.813L3.09,2H0.5C0.224,2,0,1.776,0,1.5S0.224,1,0.5,1h3
					c0.238,0,0.443,0.168,0.49,0.402L6.508,13.99C6.741,15.155,7.772,16,8.959,16H21.5c0.276,0,0.5,0.224,0.5,0.5S21.776,17,21.5,17
					z"/>
        </g>
        <g>
          <path d="M6,14c-0.25,0-0.465-0.186-0.496-0.44c-0.033-0.274,0.162-0.523,0.437-0.557l15.739-1.908
					c0.224-0.027,0.403-0.202,0.435-0.426L22.923,5H4.5C4.224,5,4,4.776,4,4.5S4.224,4,4.5,4h19c0.145,0,0.283,0.063,0.378,0.172
					c0.095,0.109,0.138,0.255,0.117,0.398l-0.892,6.241c-0.096,0.67-0.632,1.195-1.304,1.277L6.06,13.997C6.04,13.999,6.02,14,6,14z
					"/>
        </g>
      </g>
    </g>
  </g>
</svg>

export const VINWONDER_ENUM = {
  SITE_INFO: [
    {
      code: 'PHUQUOCVAP',
      id: '7610',
      name: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.PHUQUOCVAP.name"
        defaultMessage='VinWonders Phú Quốc'
      />,
      location: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.PHUQUOCVAP.location"
        defaultMessage='Phú Quốc'
      />,
      address: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.PHUQUOCVAP.address"
        defaultMessage='Gành Dầu, Phú Quốc, tỉnh Kiên Giang, Việt Nam'
      />,
    },
    {
      code: 'PHUQUOCSAF',
      id: '7613',
      name: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.PHUQUOCSAF.name"
        defaultMessage='VinWonders Safari Phú Quốc'
      />,
      location: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.PHUQUOCSAF.location"
        defaultMessage='Phú Quốc'
      />,
      address: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.PHUQUOCSAF.address"
        defaultMessage='Gành Dầu, Phú Quốc, tỉnh Kiên Giang, Việt Nam'
      />,
    },
    {
      code: 'NHATRANG',
      id: '7601',
      name: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.NHATRANG.name"
        defaultMessage='VinWonders Nha Trang'
      />,
      location: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.NHATRANG.location"
        defaultMessage='Nha Trang'
      />,
      address: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.NHATRANG.address"
        defaultMessage='Vĩnh Nguyên, Tp. Nha Trang'
      />,
    },
    {
      code: 'NAMHOIAN',
      id: '7630',
      name: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.NAMHOIAN.name"
        defaultMessage='VinWonders Nam Hội An'
      />,
      location: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.NAMHOIAN.location"
        defaultMessage='Hội An'
      />,
      address: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.NAMHOIAN.address"
        defaultMessage='Đường Thanh Niên, Bình Minh, Thăng Bình, Quảng Nam, Việt Nam'
      />,
    },
    {
      code: 'TIMECITY',
      id: '7014',
      name: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.TIMECITY.name"
        defaultMessage='VinKE Times City'
      />,
      location: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.TIMECITY.location"
        defaultMessage='Hà Nội'
      />,
      address: <FormattedMessage
        id="Experience.VinWonder.SiteInfo.TIMECITY.address"
        defaultMessage='458 Phố Minh Khai, Vĩnh Tuy, Hai Bà Trưng, Hà Nội, Việt Nam'
      />,
      hide: FLAGS.HIDE_VINWONDER_SITES,
    },
  ],
  TICKET: {
    TYPE: {
      ADULT: {
        id: 'adult',
        name: <FormattedMessage
          id="Experience.VinWonder.TicketType.Name.adult"
          defaultMessage='Người lớn'
        />,
        description: <FormattedMessage
          id="Experience.VinWonder.TicketType.Description.adult"
          defaultMessage='cao từ 140cm'
        />,
      },
      CHILD: {
        id: 'child',
        name: <FormattedMessage
          id="Experience.VinWonder.TicketType.Name.child"
          defaultMessage='Trẻ em'
        />,
        description: <FormattedMessage
          id="Experience.VinWonder.TicketType.Description.child"
          defaultMessage='100cm - 139cm'
        />,
      },
      SENIOR: {
        id: 'senior',
        name: <FormattedMessage
          id="Experience.VinWonder.TicketType.Name.senior"
          defaultMessage='Người cao tuổi'
        />,
        description: <FormattedMessage
          id="Experience.VinWonder.TicketType.Description.senior"
          defaultMessage='từ 60 tuổi'
        />,
      },
      CHILD_VINKE: {
        id: 'child',
        name: <FormattedMessage
          id="Experience.VinWonder.TicketType.Name.child"
          defaultMessage='Trẻ em'
        />,
        description: <FormattedMessage
          id="Experience.VinWonder.TicketType.Description.child.vinke"
          defaultMessage="80cm - 139 cm"
        />,
      },
    }
  }
}

export const TOUR_ENUM = {
  PASSENGER: {
    TYPE: {
      ADULT: {
        id: 'adult',
        name: <FormattedMessage
          id="Enum.Passenger.Adult"
          defaultMessage="Người lớn"
        />,
      },
      CHILD: {
        id: 'child',
        name: <FormattedMessage
          id="Enum.Passenger.Children"
          defaultMessage="Trẻ em"
        />,
      },
      INFANT: {
        id: 'infant',
        name: <FormattedMessage
          id="Enum.Passenger.Infant"
          defaultMessage="Em bé"
        />,
      },
    }
  }
}

export const VOUCHER_SEARCH_TABS = {
  TOUR: {
    id: 'tour',
    title: <FormattedMessage
      id="Voucher.Search.Tab.DefinedDatePackage"
      defaultMessage="Gói bán định ngày"
    />,
  },
  VOUCHER: {
    id: 'voucher',
    title: <FormattedMessage
      id="Voucher.Search.Tab.NotDefinedDatePackage"
      defaultMessage="Gói bán chưa định ngày"
    />,
  },
}

export const PAYMENT_ENUM = {
  METHOD: {
    INSTALLMENT: {
      FEE: {
        CUSTOMER_PAY: 'CUS',
        VINPEARL_PAY: 'VIN',
      },
      LIMIT: {
        SIX_MONTH: '6',
        TWELVE_MONTH: '12',
        SIX_TWELVE_MONTH: '6.12',
      },
      MINIMUM_PRICE_REQUIRED: 5000000,
      MINIMUM_PRICE_HOTEL_REQUIRED: 3000000,
      POLICY_PAGE_SLUG: INSTALLMENT_POLICY_PAGE_SLUG
    }
  }
}

export const PRICE_FILTER_MIN_UNIT = 1000000;
export const PRICE_FILTER_MIN_UNIT_WORD = <FormattedMessage
  id="Number.Unit.Million"
  defaultMessage="Triệu"
/>

export const PRICE_FILTER_CONFIG = {
  MIN: 0,
  MAX: PRICE_FILTER_MIN_UNIT * 100,
  STEP: PRICE_FILTER_MIN_UNIT,
  DEFAULT_RANGE: [0, PRICE_FILTER_MIN_UNIT * 100],
  CURRENCY: 'VND',
  UNIT_WORD: PRICE_FILTER_MIN_UNIT_WORD,
}

export const LOCATIONAL_TAG_GROUP_CODE = ['location', 'destination']

export const TIME_RELOAD_DATA = (60 * 1000) * 15
export const TIME_RELOAD_HOTEL = TIME_RELOAD_DATA;

export const LIST_ID_INSIDER = [1, 2, 3]

export const SKU_CODE_NOT_SHOW_DESCRIPTION = ['VI00125', 'VI00126', 'VI00127', 'VI00128', 'VI00129', 'VI00130', 'VI00131', 'VI00132'];

export const WHITE_LIST_DOMAIN = [
    'booking.vinpearl.com',
    'vpt-frontend-website-v2.stg.cloudhms.io',
    'vpt-frontend-website-v2.dev.cloudhms.io',
    'vpt-frontend-website-v2.beta.cloudhms.io'
]

export const TYPE_VW_SUBMARINE = 4 // 3 -> Tinh hoa Việt Nam | 4 -> Tàu ngầm

export const TYPE_VW_ELITE = 3

export const PARAMS_HOTEL = ['hsp', 'locationId', 'hotelId', 'arrivalDate', 'numberOfRoom', 'lengthOfStay', 'roomOccupancy', 'promotionCode']

export const paymentTypeCreditPoint = "CreditPoint"

export const BenefitType = {
  H: 'H',
  B: 'B',
  S: 'S',
  O: 'O',
  DNMP: 'DNMP',
}

export const BenefitChannel = {
  BWC: 'BWC',
  VP: 'VP',
  VPT: 'VPT',
  MOBILE: 'MOBILE',
  DNMP: 'DNMP'
}

export const BENEFIT_MEMBER = {
  MEMBER: 'MEMBER',
  PEARL: 'PEARL',
  AMBASSADOR_PEARL: 'AMBASSADOR_PEARL',
  PEARL_CLUB: 'PEARL_CLUB'
};

export const ORDER_TYPE = [
  {
    id: 1,
    name: 'VINCLUB'
  }, {
    id: 2,
    name: 'VINPEARL'
  }
]

export const PromotionMemberRates = ["PEARL5", "AMBPEARL5", "VIPPEARL10"];

export const HomepagePathname = [routes.HOME, routes.HOTEL, routes.COMBO, routes.TOUR_AND_EXPERIENCE, routes.STAYNFUN, routes.TOUR_DETAIL, ...currencyPath]
