import axiosProvider, { axiosProviderOrder } from "./api/axiosProvider";

import actionTypes from "../actionTypes";

import {
  startFetchingAction,
  stopFetchingAction,
  loadSuccess,
  loadFail,
  startFetchingFlightAction,
  stopFetchingFlightAction,
  startFetchingHoldAction,
  stopFetchingHoldAction,
} from "./commonAction";
import { flightServices } from "../services";
import { getAuthCookies } from "../utils/auth";
import { gtmUtils, LocalStorage, SessionStorage } from "../utils";
import {
  merge,
  findLast,
  get,
  set,
  orderBy,
  compact,
  cloneDeep,
  sumBy,
  every,
  isEmpty,
} from "lodash";
import {
  enumType,
  FORMAT_ONLY_DATE,
  FORMAT_ONLY_DATE_3S,
  LOCAL_STORAGE_KEY,
  paymentTypeCreditPoint,
} from "../constants";
import FlightWeekPrice from "../model/FlightWeekPrice";
import FlightCalendarItem from "../model/FlightCalendarItem";
import FlightFareRule from "../model/FlightFareRule";
import moment from "moment";
import { message } from "antd";
import { show } from "redux-modal";
import Axios from "axios";
import hotelAction from "./hotelAction";
import loggingAction from "./loggingAction";

/**
 * get airport info
 * @param keyword
 * @param type
 * @returns {Function}
 */
const getAirport = (keyword, type) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAction());
    try {
      let suffix = (type === "returnAirports" ? "/arrival" : "/departure");
      const response = await axiosProvider.get(`/airport/search${suffix}/${keyword}`);

      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(actionTypes.FETCHING_AIRPORT_SUCCESS, {
            airports: response.data.data,
            type,
          })
        );
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(null));
    }
    dispatch(stopFetchingAction());
  };
};

const getFlightsAsync = async (
  endpoint,
  params,
  currentFlightsData,
  setFlightsFn
) => {
  let currentFlights = cloneDeep(currentFlightsData);
  const airlineSourceResp = await axiosProvider.get("/flight/source", {
    params,
  });
  const airlineParams = get(airlineSourceResp, "data.data") || [];
  const callStack = [];

  const mergeFlights = (
    stateFlights = [],
    respFlights = [],
    dataSession,
    responseTime
  ) =>
    orderBy(
      compact(
        (stateFlights || []).concat(
          (respFlights || []).map((flight) =>
            merge(flight, {
              dataSession,
              responseTime: flight.responseTime || responseTime,
            })
          )
        )
      ),
      ["responseTime"],
      ["asc"]
    );

  const mergeResponseWithState = (responseData, flightsData) => {
    const response = cloneDeep(responseData);
    const responseTime = response.responseTime;
    const flights = cloneDeep(flightsData);
    const dataSession = get(response, "data.data.dataSession");

    const resultFlights = merge(flights, {
      ...(get(response, "data.data.dataSession")
        ? response.data.data
        : flights),
      departureFlights: mergeFlights(
        flights.departureFlights,
        get(response, "data.data.departureFlights"),
        dataSession,
        responseTime
      ),
      returnFlights: mergeFlights(
        flights.returnFlights,
        get(response, "data.data.returnFlights"),
        dataSession,
        responseTime
      ),
    });
    set(response, "data.data", resultFlights);
    // console.log('==== mergeResponseWithState', response)
    return response;
  };

  airlineParams.forEach((airlineCode) => {
    const Airline = cloneDeep(airlineCode);
    const reqParams = cloneDeep(Object.assign(params, { Airline }));
    callStack.push(
      new Promise((resolve, reject) => {
        axiosProvider
          .get(endpoint, { params: reqParams })
          .then((response) => {
            response.responseTime = Date.now();
            const resp = mergeResponseWithState(response, currentFlights);
            if (get(response, "data.data.dataSession")) {
              currentFlights = resp.data.data;
              setFlightsFn(resp.data.data, () => resolve(response));
            } else {
              resolve(response);
            }
          })
          .catch((responseError) => resolve({ responseError }));
      })
    );
  });
  return new Promise((resolve, reject) => {
    Promise.all(callStack)
      .then((responses) => {
        if (every(responses, (resp) => !!resp.responseError)) {
          reject(get(responses, "[0].responseError"));
        } else {
          const results = orderBy(responses, ["responseTime"], ["asc"]);

          const resp = findLast(results, (item) => item.status === 200);
          set(resp, "data.data", currentFlights);

          resolve(resp);
        }
      })
      .catch(reject);
  });
};

/**
 * Get flight list
 * @param {*} obj search object params
 */
const getFlight = (obj) => {
  return async (dispatch, getState) => {
    let flights = {};

    const setFlights = (flights = {}, callback) => {
      dispatch(
        loadSuccess(
          actionTypes.FETCHING_FLIGHT_SEARCH_SUCCESS,
          cloneDeep(flights)
        )
      );
      if (callback) callback();
    };

    dispatch(startFetchingFlightAction());
    try {
      setFlights(flights);
      const endpoint = "/flight/search";
      const params = flightServices.initFlightListObject(obj);
      // const response = await axiosProvider.get(endpoint, { params })
      LocalStorage.set(LOCAL_STORAGE_KEY.SEARCH_FLIGHT_PARAMS, obj);
      const response = await getFlightsAsync(
        endpoint,
        params,
        flights,
        setFlights
      );
      // console.log('====== getFlight', response)
      // load data success
      if (response.status === 200 && !response.errors) {
        gtmUtils.pushGtmDatalayerFlight(
          response.data.data,
          "productImpressions"
        );
        setFlights(response.data.data);

        dispatch(loadSuccess(actionTypes.CHANGE_SEARCH_FLIGHT_PARAMS, obj));
      } else {
        dispatch(
          loadFail(response.errors[0].message, null, [
            changeDepartureFlight(null),
            changeReturnFlight(null),
          ])
        );
      }
    } catch (errors) {
      dispatch(
        loadFail(errors.response, null, [
          changeDepartureFlight(null),
          changeReturnFlight(null),
        ])
      );
    }
    dispatch(stopFetchingFlightAction());
  };
};

/**
 * load flight detail
 * @param obj
 * @returns {Function}
 */
const getFlightDetail = (obj) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAction());
    try {
      const response = await axiosProvider.post(
        `/flight`,
        flightServices.initFlightDetailObject(obj)
      );
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_FLIGHT_DETAIL_SUCCESS,
            response.data.data
          )
        );
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
      const searchFlightParams =
        LocalStorage.get(LOCAL_STORAGE_KEY.SEARCH_FLIGHT_PARAMS) || {};
      dispatch(
        loadSuccess(actionTypes.CHANGE_SEARCH_FLIGHT_PARAMS, searchFlightParams)
      );
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response));
    }
    dispatch(stopFetchingAction());
  };
};

/**
 * load flight detail
 * @param obj
 * @returns {Function}
 */
const verifyFlightDetail = (obj, loadingOnSuccess = true) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAction());
    let isSuccess = false;
    try {
      const params = flightServices.initFlightDetailObject(obj);
      const response = await axiosProvider.get(`/flight/verify`, { params });
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_FLIGHT_VERIFY_DETAIL_SUCCESS,
            response.data.data
          )
        );
        isSuccess = true;
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      // fail
      if (get(errors, "response.data.errors[0].code") === "NOT_FOUND") {
        set(
          errors,
          "response.data.errors[0].code",
          enumType.apiErrorCode.FLIGHT_SESSION_NOT_FOUND
        );
      }
      console.warn("===== verifyFlightDetail Error", errors.response);
      dispatch(loadFail(errors.response));
    }
    if (loadingOnSuccess || !isSuccess) dispatch(stopFetchingAction());
  };
};

/**
 * get baggage info
 * @param obj
 * @returns {Function}
 */
const getBaggageInfo = (obj) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAction());
    try {
      const response = await axiosProvider.get(
        `/flight/baggage?${flightServices.initBaggageQuery(obj)}`
      );

      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_FLIGHT_BAGGAGE_SUCCESS,
            response.data.data
          )
        );
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      // fail
      dispatch(loadFail(errors.response));
    }
    dispatch(stopFetchingAction());
  };
};

/**
 * post dom reservation
 * @param data
 * @returns {Function}
 */

const functionSuccess = (data, paymentUrl, dispatch) => {
  if (data.isCombo) {
    SessionStorage.remove(LOCAL_STORAGE_KEY.PHS_ORDER_BUNDLE);
  } else {
    let dateNow = moment.utc();
    const phsOrderHotelStorage = SessionStorage.get(LOCAL_STORAGE_KEY.PHS_ORDER_HOTEL);
    if(phsOrderHotelStorage && Object.keys(phsOrderHotelStorage).length > 0){
      if(dateNow.diff(moment(new Date(phsOrderHotelStorage?.onPageTime))) > 0){
        SessionStorage.remove(LOCAL_STORAGE_KEY.PHS_ORDER_HOTEL);
        SessionStorage.remove(LOCAL_STORAGE_KEY.PHS_ORDER_HOTEL_ID);
      }
    }
    const phsOrderStayFunStorage = SessionStorage.get(LOCAL_STORAGE_KEY.PHS_ORDER_HOMESTAY);
    if(phsOrderStayFunStorage && Object.keys(phsOrderStayFunStorage).length > 0){
      if(dateNow.diff(moment(new Date(phsOrderStayFunStorage?.onPageTime))) > 0){
        SessionStorage.remove(LOCAL_STORAGE_KEY.PHS_ORDER_HOMESTAY)
      }
    }
    //SessionStorage.remove(LOCAL_STORAGE_KEY.PHS_ORDER_HOMESTAY)
  }
  dispatch(
    loadSuccess(
      actionTypes.POST_ORDER_DOM_RESERVATION_SUCCESS,
      null,
      null,
      null,
      [
        loadSuccess(actionTypes.CHANGE_DEPARTURE_FLIGHT_SUCCESS),
        loadSuccess(actionTypes.CHANGE_RETURN_FLIGHT_SUCCESS),
        loadSuccess(actionTypes.CHANGE_HOTEL_SUCCESS),
        loadSuccess(actionTypes.BOOKING_CHECKOUT_SUCCESS, paymentUrl),
        loadSuccess(actionTypes.PHS_ORDER_HOTEL_SUCCESS),
        loadSuccess(actionTypes.STOP_FETCHING_HOLD),
      ]
    )
  );
  dispatch(stopFetchingHoldAction());
};

const functionFail = (errors, dispatch, failRedirectPath, onPHSInValid) => {
  dispatch(stopFetchingHoldAction());
  dispatch(stopFetchingAction());
  const errorGet = get(errors, "response.data.errors[0].code");
  switch (errorGet) {
    case enumType.apiErrorCode.FLIGHT_HOLDING_TIMEOUT:
      dispatch(show("modal", { message: errors.response }));
      break;
    case enumType.apiErrorCode.PHS_SESSION_NOT_VALID:
      dispatch(loadSuccess(actionTypes.PHS_ORDER_HOTEL_RELOAD, true));
      onPHSInValid && onPHSInValid();
      break;
    case enumType.apiErrorCode.PRICES_HAD_CHANGED:
      dispatch(
        loadSuccess(
          actionTypes.PRICE_CHANGED_CONFIRM,
          get(errors, "response.data.errors[0]")
        )
      );
      break;
    case enumType.apiErrorCode.MRU_BUNDLE_PRICES_HAD_CHANGED:
      dispatch(
        loadSuccess(
          actionTypes.SET_BOOKING_BUNDLE_ERROR,
          errors.response.data.errors[0]
        )
      );

      dispatch(stopFetchingAction());

      setTimeout(() => {
        dispatch(loadSuccess(actionTypes.SET_BOOKING_BUNDLE_ERROR, null));
      }, 1000);
      break;
    case enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_HOTEL_REQUEST:
    case enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_RATE_REQUEST:
    case enumType.apiErrorCode.CHECK_PROMOTION_CODE_FAILED:
      dispatch(stopFetchingAction());
      dispatch(
        loadSuccess(
          actionTypes.SET_BOOKING_BUNDLE_PROMOTION_MARKUP_ERROR,
          errorGet === enumType.apiErrorCode.CHECK_PROMOTION_CODE_FAILED
            ? "notFound"
            : "invalid"
        )
      );
      break;
    case enumType.apiErrorCode.COMMIT_BOOKING_FAILED:
      failRedirectPath += `?error=${enumType.comboErrors.COMMIT_BOOKING_FAILED}`;
      break;
    case enumType.apiErrorCode.COMMIT_BOOKING_FAILED:
      failRedirectPath += `?error=${enumType.comboErrors.COMMIT_BOOKING_FAILED}`;
      dispatch(loadFail(null, failRedirectPath));
      break;
    case enumType.apiErrorCode.SYS_CRD_INVALID_PENDING_ORDER:
      // dispatch(stopFetchingAction());
      dispatch(loadFail(errors.response, null, [stopFetchingAction()]));
      break;
    default:
      dispatch(loadFail(errors.response, failRedirectPath));
      break;
  }

  // if (
  //   get(errors, "response.data.errors[0].code") ===
  //   enumType.apiErrorCode.FLIGHT_HOLDING_TIMEOUT) {
  //     dispatch(show('modal', { message: errors.response }))
  // } else if (
  //   get(errors, "response.data.errors[0].code") ===
  //   enumType.apiErrorCode.PHS_SESSION_NOT_VALID
  // ) {
  //   dispatch(loadSuccess(actionTypes.PHS_ORDER_HOTEL_RELOAD, true));
  // } else if (
  // get(errors, "response.data.errors[0].code") ===
  // enumType.apiErrorCode.PRICES_HAD_CHANGED
  // ) {
  //   dispatch(
  //     loadSuccess(
  //       actionTypes.PRICE_CHANGED_CONFIRM,
  //       get(errors, "response.data.errors[0]")
  //     )
  //   );
  // } else {
  //   const errorGet = get(errors, "response.data.errors[0].code");
  //   if (errorGet === enumType.apiErrorCode.MRU_BUNDLE_PRICES_HAD_CHANGED) {
  //     dispatch(
  //       loadSuccess(
  //         actionTypes.SET_BOOKING_BUNDLE_ERROR,
  //         errors.response.data.errors[0]
  //       )
  //     );

  //     dispatch(stopFetchingAction());

  //     return setTimeout(() => {
  //       dispatch(loadSuccess(actionTypes.SET_BOOKING_BUNDLE_ERROR, null));
  //     }, 1000);
  //   }
  //   if (
  //     errorGet ===
  //       enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_HOTEL_REQUEST ||
  //     errorGet ===
  //       enumType.apiErrorCode.MKR_PROMOTION_NOT_APPLY_FOR_LIST_RATE_REQUEST ||
  //     errorGet === enumType.apiErrorCode.CHECK_PROMOTION_CODE_FAILED
  //   ) {
  //     dispatch(stopFetchingAction());
  //     return dispatch(
  //       loadSuccess(
  //         actionTypes.SET_BOOKING_BUNDLE_PROMOTION_MARKUP_ERROR,
  //         errorGet === enumType.apiErrorCode.CHECK_PROMOTION_CODE_FAILED
  //           ? "notFound"
  //           : "invalid"
  //       )
  //     );
  //   }
  //   if (
  //     get(errors, "response.data.errors[0].code") ===
  //     enumType.apiErrorCode.COMMIT_BOOKING_FAILED
  //   ) {
  //     failRedirectPath += `?error=${enumType.comboErrors.COMMIT_BOOKING_FAILED}`;
  //   }
  //   dispatch(loadFail(null, failRedirectPath));
  // }
};

let cancelToken = Axios.CancelToken.source();

const postDomReservation = (data, callbacks) => {
  return async (dispatch, getState) => {
    if (callbacks?.cancel) {
      dispatch(stopFetchingHoldAction());
      dispatch(stopFetchingAction())
      dispatch(hotelAction.removePaymentUrl())
      cancelToken.cancel("user cancel");
      return;
    }
    let failRedirectPath = "/order";

    try {
      cancelToken = Axios.CancelToken.source();
      const authInfo = getAuthCookies();
      let config = { cancelToken: cancelToken.token };
      if (authInfo && authInfo.auth && authInfo.auth.accessToken) {
        config = {
          ...config,
          headers: {
            Authorization: `Bearer ${authInfo.auth.accessToken}`,
          },
        };
      }
      if (data?.isHotel) {
        if (getState().fetching.isLoading) return;
        dispatch(startFetchingAction());
        const responseData = await axiosProviderOrder.post(
          `/frontend/order/bookingcheckout`,
          data,
          config
        );
        if (responseData.status === 200 && !responseData.errors) {
          functionSuccess(data, responseData.data.data.paymentUrl, dispatch);
        } else {
          dispatch(loggingAction.pushLogGA4({
            actionName: 'checkout',
            message: `postDomReservation - response error: ${JSON.stringify(responseData)}`
          }))
          dispatch(loadFail(responseData?.errors[0], failRedirectPath));
          // dispatch(functionFail(errors, dispatch, failRedirectPath))
        }
      } else {
        dispatch(startFetchingAction());
        dispatch(startFetchingHoldAction());
        const newData = { ...data };
        if (data?.creditPointCalculation?.remainCash === 0) {
          newData.paymentType = paymentTypeCreditPoint;
          newData.paymentMethod = "";
        }
        const response = await axiosProviderOrder.post(
          `/frontend/order/booking/hold`,
          newData,
          config
        );
        const bookingHoldStatus = () => {
          console.log('bookingHoldStatus', response);
          axiosProviderOrder
            .get(
              `/frontend/order/booking/hold-status/${response.data.data}`,
              {},
              config
            )
            .then((res) => {
              if (!isEmpty(res.data.data)) {
                // clearInterval(bookingHoldhold);
                callbacks?.onClearTimer && callbacks.onClearTimer();
                cancelToken.cancel("user cancel");
                functionSuccess(
                  data,
                  res.data.data.paymentInfo.paymentUrl,
                  dispatch
                );
              }
            })
            .catch((errors) => {
              // fail
              // clearInterval(bookingHoldhold);
              callbacks?.onClearTimer && callbacks.onClearTimer();
              if (errors.message === "user cancel") {
                return;
              }
              const errorReceived = get(errors, "response.data.errors[0].code");
              if (errorReceived == enumType.apiErrorCode.DUPLICATE_INFORMATION) {
                dispatch(stopFetchingHoldAction());
                dispatch(stopFetchingAction());
                callbacks?.onFailed && callbacks.onFailed(enumType.apiErrorCode.DUPLICATE_INFORMATION);
                return;
              }
              dispatch(loggingAction.pushLogGA4({
                actionName: 'checkout',
                message: `postDomReservation - catch 01: ${errorReceived} | ${JSON.stringify(errors)}`
              }))
              functionFail(errors, dispatch, failRedirectPath);
            });
        };
        console.log('callbacks', callbacks);
        callbacks?.onSuccess && callbacks.onSuccess(bookingHoldStatus);
      }
    } catch (errors) {
      console.log('errorserrors', errors);
      if (errors.message === "user cancel") {
        return;
      }
      dispatch(loggingAction.pushLogGA4({
        actionName: 'checkout',
        message: `postDomReservation - catch 02: ${JSON.stringify(errors)}`
      }))
      functionFail(errors, dispatch, failRedirectPath, callbacks?.onPHSInValid);
    } finally {
      if (callbacks?.setSubmitting) callbacks?.setSubmitting();
    }
  };
};
/**
 * get order detail
 * @param orderId
 * @returns {Function}
 */
const getOrderDetail = (orderId) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAction());
    try {
      const response = await axiosProvider.get(`/order/${orderId}`);

      // load data success
      if (response.status === 200 && !response.errors) {
        dispatch(
          loadSuccess(
            actionTypes.FETCHING_ORDER_DETAIL_SUCCESS,
            response.data.data
          )
        );
      } else {
        dispatch(loadFail(response.errors[0].message));
      }
    } catch (errors) {
      dispatch(loadFail(null));
    }
    dispatch(stopFetchingAction());
  };
};

/**
 * change departure flight
 * @param currentFlight
 * @returns {Function}
 */
const changeDepartureFlight = (currentFlight) => {
  return (dispatch, getState) => {
    dispatch(startFetchingAction());
    const { flight } = getState();
    const { endPoint, startPoint } = flightServices.getFlightSession(
      flight,
      false
    );

    const departureFlight = currentFlight
      ? {
          ...currentFlight,
          startPoint: startPoint,
          endPoint: endPoint,
        }
      : null;

    const dataSession =
      currentFlight && currentFlight.dataSession
        ? currentFlight.dataSession
        : flight && flight.flights
        ? flight.flights.dataSession
        : null;

    dispatch(
      loadSuccess(actionTypes.CHANGE_DEPARTURE_FLIGHT_SUCCESS, departureFlight)
    );
    dispatch(
      loadSuccess(
        actionTypes.CHANGE_FLIGHT_SESSION,
        currentFlight ? dataSession : null
      )
    );
    dispatch(stopFetchingAction());
  };
};

const resetFlight = (actionType) => {
  return (dispatch) => {
    if (!actionType) {
      dispatch(loadSuccess(actionTypes.CHANGE_DEPARTURE_FLIGHT_SUCCESS, null));
      dispatch(loadSuccess(actionTypes.CHANGE_RETURN_FLIGHT_SUCCESS, null));
    } else {
      if (actionType === "DEPARTURE_FLIGHT") {
        dispatch(
          loadSuccess(actionTypes.CHANGE_DEPARTURE_FLIGHT_SUCCESS, null)
        );
      }
      if (actionType === "RETURN_FLIGHT") {
        dispatch(loadSuccess(actionTypes.CHANGE_RETURN_FLIGHT_SUCCESS, null));
      }
    }
  };
};

const resetBookingMarkupError = () => {
  return (dispatch) => {
    dispatch(
      loadSuccess(actionTypes.SET_BOOKING_BUNDLE_PROMOTION_MARKUP_ERROR, null)
    );
  };
};

/**
 * change return flight
 * @param currentFlight
 * @returns {Function}
 */
const changeReturnFlight = (currentFlight) => {
  return (dispatch, getState) => {
    dispatch(startFetchingAction());
    const { flight } = getState();
    const { endPoint, startPoint } = flightServices.getFlightSession(
      flight,
      true
    );

    const returnFlight = currentFlight
      ? {
          ...currentFlight,
          startPoint: startPoint,
          endPoint: endPoint,
        }
      : null;

    const dataSession =
      currentFlight && currentFlight.dataSession
        ? currentFlight.dataSession
        : flight && flight.flights
        ? flight.flights.dataSession
        : null;

    dispatch(
      loadSuccess(actionTypes.CHANGE_RETURN_FLIGHT_SUCCESS, returnFlight)
    );

    dispatch(
      loadSuccess(
        actionTypes.CHANGE_FLIGHT_SESSION,
        currentFlight ? dataSession : null
      )
    );
    dispatch(stopFetchingAction());
  };
};

const switchAirport = () => {
  return (dispatch) => {
    dispatch(loadSuccess(actionTypes.SWITCH_AIRPORT));
  };
};

/**
 * Get flight list
 * @param {*} obj search object params
 */
const getFlightWeekPrices = (obj) => {
  const getRequestWeek = (dateField) => {
    if (!obj[dateField]) return;
    const endpoint = "/flight/searchweek";
    const params = flightServices.initFlightSearchWeekParams(
      Object.assign(obj, { date: obj[dateField] })
    );
    return axiosProvider.get(endpoint, { params });
  };
  return async (dispatch, getState) => {
    try {
      const departureReq = getRequestWeek("departureDate");
      const returnReq = getRequestWeek("returnDate");
      const responses = await Promise.all(compact([departureReq, returnReq]));

      if (
        responses.some(
          (response) => response.status === 200 && !response.errors
        )
      ) {
        const allData = responses.reduce(
          (arr, current) => arr.concat(get(current, "data.data") || []),
          []
        );
        const weekData = allData.map((item) => new FlightWeekPrice(item));
        dispatch(
          loadSuccess(actionTypes.FETCHING_FLIGHT_WEEKPRICES_SUCCESS, weekData)
        );
      } else {
        dispatch(loadFail(responses[0].errors[0].message, null));
      }
    } catch (errors) {
      dispatch(loadFail(errors.response, null));
    }
  };
};

/**
 * Get flight list
 * @param {*} obj search object params
 */
const getFlightMonth = (obj) => {
  const getRequestMonth = (dateField, dependedDate = undefined) => {
    if (!obj[dateField]) return;
    let date = obj[dateField];
    if (
      dependedDate &&
      moment(dependedDate, FORMAT_ONLY_DATE).isSame(
        moment(date, FORMAT_ONLY_DATE),
        "month"
      )
    ) {
      date = moment(date, FORMAT_ONLY_DATE)
        .add(1, "M")
        .format(FORMAT_ONLY_DATE);
    }
    // console.log(date, 'date')
    const endpoint = "/flight/searchmonth";
    const params = flightServices.initFlightSearchMonthParams(
      Object.assign(obj, { date })
    );
    return axiosProvider.get(endpoint, { params });
  };
  return async (dispatch, getState) => {
    dispatch(startFetchingFlightAction());
    try {
      const departureReq = getRequestMonth("departureDate");
      const returnReq = getRequestMonth("returnDate", obj.departureDate);

      const responses = await Promise.all(compact([departureReq, returnReq]));
      // load data success
      if (
        responses.some(
          (response) => response.status === 200 && !response.errors
        )
      ) {
        const allData = responses.reduce(
          (arr, current) => arr.concat(get(current, "data.data") || []),
          []
        );
        const monthData = allData.map((item) => new FlightCalendarItem(item));
        // console.log('===== getFlightMonth', monthData, responses)
        dispatch(
          loadSuccess(actionTypes.FETCHING_FLIGHT_MONTH_SUCCESS, monthData)
        );
      } else {
        dispatch(loadFail(responses[0].errors[0].message, null));
      }
    } catch (errors) {
      dispatch(loadFail(errors.response, null));
    }
    dispatch(stopFetchingFlightAction());
  };
};

/**
 * Get
 * @param {*} obj search object params
 */
const getFareRule = (params) => {
  return async (dispatch, getState) => {
    dispatch(loadSuccess(actionTypes.FETCHING_FLIGHT_FARERULE_SUCCESS, null));
    try {
      const endpoint = "/flight/fare-rule";
      const response = await axiosProvider.get(endpoint, { params });
      // load data success
      if (response.status === 200 && !response.errors) {
        const fareRule = new FlightFareRule(get(response, "data.data", {}));
        dispatch(
          loadSuccess(actionTypes.FETCHING_FLIGHT_FARERULE_SUCCESS, fareRule)
        );
      } else {
        dispatch(loadFail(response.errors[0].message, null));
      }
    } catch (errors) {
      dispatch(loadFail(errors.response, null));
    }
  };
};

export default {
  getAirport,
  getFlight,
  getFlightDetail,
  verifyFlightDetail,
  getBaggageInfo,
  postDomReservation,
  getOrderDetail,
  changeDepartureFlight,
  changeReturnFlight,
  switchAirport,
  getFlightWeekPrices,
  getFlightMonth,
  getFareRule,
  resetFlight,
  resetBookingMarkupError,
};
